<template>
  <div id="news">
    <div class="banner">
    </div>
    <div id="container">
      <div class="main_title"> NEWS CENTER</div>
      <div class="main_l"></div>
      <div class="main">
        <div class="main_content">
          <div class="item" v-for="(news, i) in newsData" :key="i" @click="RunDetail(news.news_id)">
            <div class="common_item" :key="i">
              <div class="left">
                <img class="line" :src=news.images alt="" />
              </div>
              <div class="right">
                <h2>{{ news.news_title }}</h2>
                <p>{{ news.brief_content }}</p>
                <div class="more">
                  <span class="span1">{{ news.t1 }}</span>
                  <span class="span2" @click="RunDetail(news.news_id)">查看详情</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="pageNum"
              :page-size="pageSize" :page-sizes="[4, 8, 10, 15]" layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
        <!-- <div class="cover" v-show="isShow"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsData } from "@/request.js"
export default {
  name: "News",
  data () {
    return {
      currentIndex: -1,
      currentPage: 0,
      pageNum: 1,
      total: 0,
      pageSize: 4,
      newsData: [],
      filterNews: [],
    }
  },
  computed: {
    container () {
      return document.getElementById("container")
    },
  },
  methods: {
    handleCurrentChange (e) {
      this.pageNum = e
      this.init()
    },
    handleSizeChange (e) {
      this.pageSize = e
      this.init()
    },
    init () {
      this.http.service({
        url: '/tdp/data/gateway/v2/news_search_new/select',   //首页新闻资讯
        data: {
          "news_type": "news",
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.code === 200) {
          this.total = res.totalRow
          this.newsData = res.data.map(item => {
            return {
              ...item,
              title: item.news_title,
              des: item.brief_content,
              url: item.images,
              t1: '九州云智 | ' + item.create_date,
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    RunDetail (index) {
      this.$router.push({ path: '/news-details', query: { id: index, type: 'news' } })
    },
    resetCurrentIndex () {
      this.container.style.height = "13.74rem"
      this.container.children[0].style.height = "13.66rem"
      this.currentIndex = -1
    },
  },
  created () {
    //  this.newsData = getNewsData();
    this.filterNews = getNewsData().filter((news, i) => i > 0)
    this.init()
  },
}
</script>
<style lang='scss' scoped>
#news {
  width: 100%;
  background: #ffffff;

  .banner {
    position: relative;
    height: 6rem;
    background: url("../assets/image/news/b_1.png") no-repeat;
    background-size: cover;

    p:nth-child(1) {
      position: absolute;
      top: 0.92rem;
      left: 5.92rem;
      font-size: 0.36rem;
      font-weight: bold;
      color: #fffefe;
    }

    p:nth-child(2) {
      position: absolute;
      top: 1.62rem;
      left: 5.92rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #ffffff;
    }
  }

  #container {
    position: relative;
    // height: 13.74rem;
    z-index: 1;

    .main_title {
      width: 100%;
      height: 0.53rem;
      font-size: 0.4rem;
      font-family: MicrosoftYaHei;
      color: #0A121F;
      line-height: 0.53rem;
      margin-top: 0.64rem;
      text-align: center;
    }

    .main_l {
      width: 0.48rem;
      height: 0.06rem;
      margin-top: 0.16rem;
      background: #0560D6;
      position: absolute;
      left: 50%;
      margin-left: -0.24rem;
      // margin-bottom: 0.56rem;
    }

    .main {
      margin-top: 0.56rem;
      padding: 0 2.6rem;

      .main_content {
        .item {
          width: 14rem;
          height: 3.07rem;
        }

        .item:hover {
          box-shadow: 0rem 0rem 0.16rem 0rem #D1E0F5;
        }

        .common_item,
        .big_item {
          display: flex;
          height: 100%;
          cursor: pointer;

          .left {
            width: 4.41rem;

            img {
              margin: 0.41rem 0 0.41rem 0.4rem;
              width: 4rem;
              height: 2.25rem;
            }
          }

          .right {
            flex: 1;
            height: 100%;
            position: relative;
            padding-left: 0.4rem;
            padding-top: 0.41rem;
            padding-right: 0.4rem;

            h2 {

              height: 0.31rem;
              font-size: 0.24rem;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #0A121F;
              line-height: 0.31rem;
            }

            p {
              width: 100%;
              // height: 0.47rem;
              margin-top: 0.32rem;
              height: 0.78rem;
              font-size: 0.14rem;
              font-family: MicrosoftYaHei;
              color: #565E66;
              line-height: 0.26rem;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .more {
              width: 100%;
              height: 0.4rem;
              margin-top: 0.32rem;

              .span1 {
                width: 1.53rem;
                height: 0.26rem;
                font-size: 0.14rem;
                font-family: MicrosoftYaHei;
                color: #9AA3B2;
                line-height: 0.4rem;
              }

              .span2 {
                float: right;
                text-align: center;
                width: 1.2rem;
                height: 0.4rem;
                border-radius: 0.24rem;
                border: 0.01rem solid #0560D6;
                font-size: 0.14rem;
                font-family: MicrosoftYaHei;
                color: #0560D6;
                line-height: 0.4rem;
              }
            }
          }
        }

        .common_item {
          height: 100%;
        }

      }

      .cover {
        position: absolute;
        top: 11.8rem;
        left: 0.93rem;
        width: 10.99rem;
        height: 0.76rem;
        background: linear-gradient(0deg,
            #f2f6f9 0%,
            rgba(255, 255, 255, 0.02) 100%);
      }

      .pagination {
        margin: 0.2rem 0;
        text-align: center;
        display: flex;
        justify-content: end;
      }
    }
  }
}

.color01 {
  background: #2164ee !important;
  color: #ffffff !important;
}
</style>
